<script setup>
  import ConnectMetamask from "./components/ConnectMetamask.vue"
  import WalletConnect from "./components/WalletConnect.vue"
  import Default from "./components/Default.vue"
  import Transactions from "./components/Transactions.vue"
  import Modal from "./components/Modal.vue";
  import Debug from "./components/Debug.vue"
  import { useStatusStore } from "./stores/useStatusStore.js"
  import { onMounted, ref } from "vue"

  const ENV = import.meta.env.VITE_ENVIRONMENT
  const statusStore = useStatusStore()

  const modalRef = ref()
  const metaMaskRef = ref()
  const walletConnectRef = ref()
  let mainComponent = Default

  statusStore.updateWallet('', [])
  statusStore.updateStatus(0, 'Initializing app')

  const connect = () => {
    if (statusStore.code === 1) {
      statusStore.updateStatus(2, 'Choose a wallet connector')
    } else {
      statusStore.updateStatus(1, 'App mounted, ready')
    }
  }

  const showWalletMenu = () => {
    //console.log('show wallet menu')
    // if (statusStore.wallet.name === 'metamask') {
    //
    // }
    // if (statusStore.wallet.name.startsWith('walletconnect') === true) {
    //   walletConnectRef.value = ref(document.getElementById('walletConnectRef'))
    //   console.log(walletConnectRef)
    //   walletConnectRef.showModal()
    // }
  }

  const loadMainComponent = (component) => {
    if (component === 'Transactions') {
      component = Transactions
    }
    mainComponent = component
  }

  const disconnectAccount = () => {
    if (statusStore.wallet.name === 'metamask') {
      metaMaskRef.disconnectMetaMask()
      alert('It\'s not possible to disconnect your MetaMask wallet from our app with Vanilla JS. Please reload the page to reconnect to your wallet or open MetaMask to disconnect.')
    }
    if (statusStore.wallet.name.startsWith('walletconnect') === true) {
      walletConnectRef.disconnectWalletConnect()
    }
  }

  onMounted(()=> {
    statusStore.updateStatus(1, 'App mounted, ready')
    /**
     * Toggle debug element with ctrl + d key
     * @type {*[]}
     */
    let keys = []
    document.addEventListener('keydown', (event) => {
      keys.push(event.key)
      if (keys.includes('Control') && keys.includes('d')) {
        statusStore.debug = (statusStore.debug === false)
      }
    })
    document.addEventListener('keyup', (event) => {
      if (event.key !== 'd' || !event.ctrlKey) {
        keys = []
      }
    })
  })

</script>

<template>
  <header class="fixed h-24 w-full z-10 backdrop-blur-sm bg-white/50">
    <div class="fixed ml-6 mt-3">
      <img src="./assets/GreenhoodLogo.png" class="mt-2 ml-2 h-[80px]">
    </div>
    <div class="fixed mt-6 left-[130px] p-4 w-76 rounded-full border-2 token-color bg-gray-100 text-center"  v-if="statusStore.code === 12 || statusStore.code === 23">
      {{ statusStore.getRoundedBalanceActiveAccount }} {{ statusStore.getTokenActiveAccount }}
    </div>
    <div class="fixed p-4 mt-6 right-72 text-green-700 font-bold" v-if="Object.keys(statusStore.chain).length !== 0">
      <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
           viewBox="0 0 428 428" class="mb-1 mr-2 fill-green-700 h-[24px] inline">
        <path d="M125.8,243.7c12.3,0,24.3-4.1,34-11.6l-78-78c-18.8,24.3-14.3,59.3,10,78.1
          C101.6,239.6,113.5,243.7,125.8,243.7L125.8,243.7z"/>
        <path d="M357.8,188c0-12.3-4.1-24.3-11.6-34l-78,78c24.3,18.8,59.2,14.3,78-10
          C353.7,212.3,357.8,200.3,357.8,188z"/>
        <path d="M397.1,103.1l-34.5,34.5c27.8,33.3,23.4,82.9-9.9,110.7c-29.2,24.4-71.6,24.4-100.8,0L214,286.2
          l-37.8-37.8c-33.3,27.8-82.9,23.4-110.7-9.9c-24.4-29.2-24.4-71.6,0-100.8L47.8,120L31,103.1C10.7,136.5,0,174.9,0,214
          c0,118.2,95.8,214,214,214s214-95.8,214-214C428.1,174.9,417.3,136.5,397.1,103.1z"/>
        <path d="M368.8,66.3c-81.5-85.5-216.9-88.7-302.4-7.2c-2.5,2.4-4.9,4.8-7.2,7.2c-5.3,5.6-10.3,11.4-15,17.5
          L214,253.7L383.8,83.8C379.2,77.7,374.1,71.9,368.8,66.3z M214,28c50,0,96.6,19.3,131.6,54.5L214,214.1L82.4,82.5
          C117.4,47.3,164,28,214,28z"/>
      </svg>
      {{ statusStore.chain.name }} Chain
    </div>
    <div class="fixed right-0 mt-6 mr-6">
      <button class="p-4 mb-2 w-64" @click="connect" v-show="statusStore.wallet.name === ''">
        Connect jouw wallet
      </button>
      <ConnectMetamask id="metaMaskRef" @click="showWalletMenu" ref="metaMaskRef" @loadMainComponentEmit="loadMainComponent" />
      <WalletConnect id="walletConnectRef" @click="showWalletMenu" ref="walletConnectRef" @loadMainComponentEmit="loadMainComponent" />
    </div>
  </header>
  <main class="container">
    <div class="grid grid-cols-6 pt-32 min-h-screen">
      <component :is="mainComponent"></component>
      <div class="hidden">
        <button @click="modalRef.show()">Open modal</button>
        <Modal ref="modalRef">
          This is the modal
        </Modal>
      </div>
    </div>
  </main>
  <footer>
    <Debug v-if="statusStore.debug === true" />
  </footer>
</template>

<style scoped lang="scss">

</style>