<template>
  <div class="col-span-full place-self-center -mt-24">
    <div class="text-4xl">Welkom bij de Greenhood inzicht dApp</div>
  </div>
</template>

<script setup>

</script>

<style scoped>

</style>