<script setup>

  import { useStatusStore } from "@/stores/useStatusStore.js"
  import { onMounted, ref } from "vue"
  import { Core } from '@quicknode/sdk'
  import { formatEther } from "viem";
  import Datepicker from 'vue3-datepicker'
  import { saveAs } from 'file-saver';

  const statusStore = useStatusStore()

  const transactions = ref([]);
  const displayedTransactions = ref([]);
  const totals = ref({
    'sum': 0,
    'min': 0,
    'saldo': 0,
  });
  const showCurrentBlockNumber = ref();
  const init = async () => {

    // 2 - with ethers v6 - docs:
    // - https://docs.gnosischain.com/developers/interact/ethers-js
    // - https://docs.ethers.org/v5/api/providers/api-providers/ with the method getHistory()

    await loadTxGnosisscan()
  }

  const loadTxQuickNode = async () => {
    transactions.value = [];
    // 3 - QuickNode SDK
    // Docs:
    // - https://www.quicknode.com/docs/gnosis
    const endPoint = 'https://white-few-arrow.xdai.quiknode.pro/9a8332f4fdee3ee34065787ec1e8411077f8996a/';
    const core = new Core({
      endpointUrl: endPoint,
    })
    // Get transaction count from wallet address
    // https://www.quicknode.com/docs/gnosis/eth_getTransactionCount
    let transactionCount = await core.client
        .getTransactionCount({
          address: statusStore.wallet.accounts[0].address,
        });
    console.log(transactionCount)

    // Get transactions from address answer (october 2023): https://forum.quicknode.com/t/get-transactions-from-address/382
    const transactionMatches = []
    // Get latest block number


    let currentBlockNumber = await core.client.getBlockNumber()
    // Iterate over blocks for transactions till block 250000000
    let block = await core.client.getBlock({
      blockNumber: currentBlockNumber
    });

    const iterationsPerChunk = 2500;
    const startTime = performance.now();
    for (let i = 0; currentBlockNumber > 27500000; ++i) {
      if (i && i % iterationsPerChunk === 0) {
        await oneMoment();
      }

      // Iterate over transactions to match the to and from addresses against current address
      for (const tx_hash of block.transactions) {
        const tx = await core.client.getTransaction({
          hash: tx_hash
        });
        if (tx.to === statusStore.wallet.accounts[0].address) {
          transactionMatches.push(tx)
        }
        if (tx.from === statusStore.wallet.accounts[0].address) {
          transactionMatches.push(tx)
        }
      }

      showCurrentBlockNumber.value = currentBlockNumber;
      currentBlockNumber--
    }
    const elapsedTime = performance.now() - startTime;
    console.log(`elapsedTime: ${(elapsedTime * 0.001).toFixed(2)}seconds`);
    await showTransactions(transactionMatches)
  }

  function oneMoment() {
    return new Promise(resolve => setTimeout(resolve));
  }

  const loadTxGnosisscan = async () => {
    // Transactions already loaded.
    if (transactions.value.length !== 0) {
      return await showTransactions(transactions.value)
    }
    transactions.value = [];
    // 1 - connect to the API from gnosisscan.io - docs:
    // - https://docs.gnosisscan.io/api-endpoints/accounts#get-a-list-of-normal-transactions-by-address max 10000 results
    // - for the smart contract: https://docs.gnosisscan.io/api-endpoints/accounts#get-a-list-of-erc20-token-transfer-events-by-address
    //const res_xdai = await fetch('https://api.gnosisscan.io/api?module=account&action=txlist&address='+statusStore.wallet.accounts[0].address+'&startblock=0&endblock=99999999&page=1&offset=10&sort=asc')
    const apiKey = 'T8BSWUYGP5U42PGY6QD96HISMYXH1AGHIQ';
    const result = await fetch('https://api.gnosisscan.io/api?module=account&action=tokentx&contractaddress=0xcb444e90d8198415266c6a2724b7900fb12fc56e&address='+statusStore.wallet.accounts[0].address+'&page=1&offset=0&startblock=0&endblock=99999999&sort=asc&apikey='+apiKey)
      .then((response) =>
        response.json()
      )
      .then(data => {
        return data
      })
      .catch(error => {
        return console.log(error)
      })

    if (result.message === 'OK' && result.status === '1') {
      transactions.value = result.result
      await showTransactions(result.result)
    }
  }
  const getDate = (timestamp) => {
    // Multiply with 1000 to transform the timestamp from second to milliseconds
    timestamp = parseInt(timestamp) * 1000
    const date = new Date(timestamp)
    return date.toLocaleDateString()
  }

  const setTotals = () => {
    totals.value = {
      'sum': 0,
      'min': 0,
      'saldo': 0,
    }
    for (const transaction of displayedTransactions.value) {
      if (transaction.to === statusStore.wallet.accounts[0].address.toLowerCase()) {
        const val = Math.round(formatEther(transaction.value));
        totals.value.sum += val
      }
      if (transaction.from === statusStore.wallet.accounts[0].address.toLowerCase()) {
        const val = Math.round(formatEther(transaction.value) * 1e2) / 1e2;
        totals.value.min -= val
      }
      totals.value.saldo = totals.value.min + totals.value.sum;
    }
  }

  const showTransactions = async (data, filter = {}) => {
    displayedTransactions.value = []
    if (filter?.date !== undefined) {
      if (filter.date?.fromDate !== undefined && filter.date?.toDate !== undefined) {
        // Filter result on given date filter.
        data.forEach((element, index) => {
          const fromDateFilter = new Date(filter.date.fromDate)
          const toDateFilter = new Date(filter.date.toDate)
          // If timestamp is within given date/timestamp range, we show it.
          if ((parseInt(element.timeStamp) * 1000) >= fromDateFilter.getTime() && (parseInt(element.timeStamp) * 1000) <= toDateFilter.getTime()) {
            displayedTransactions.value.push(element)
          }
        })
      }
    } else {
      // Show all when no filter is set.
      displayedTransactions.value = data
    }
    // Sort transactions from new to old.
    displayedTransactions.value.sort((a,b) => {
      return b.timeStamp - a.timeStamp
    })
    // Show table with transactions
    document.getElementById('transactionsTable').classList.remove('hidden')
    // Calculate and set totals
    await setTotals()
    document.getElementById('totalSum').getElementsByTagName('span')[0].innerHTML = totals.value.sum.toFixed(2);
    document.getElementById('totalMin').getElementsByTagName('span')[0].innerHTML = Math.abs(totals.value.min).toFixed(2);
    document.getElementById('totalSaldo').getElementsByTagName('span')[0].innerHTML = totals.value.saldo.toFixed(2);
  }

  const currentDate = ref(new Date())
  const pickedDateFrom = ref()
  const pickedDateTo = ref()

  const filter = () => {
    // Get values, start and end datum from the datepicker.
    if (pickedDateFrom.value === undefined || pickedDateTo.value === undefined) {
      console.log('No valid date range')
      return
    }
    if (pickedDateFrom.value === null || pickedDateTo.value === null) {
      showTransactions(transactions.value, {})
      return
    }
    const newFromDate = new Date(pickedDateFrom.value)
    const newToDate = new Date(pickedDateTo.value)
    // Update list with transactions with date filter.
    const filter = {
      date: {
        fromDate: newFromDate,
        toDate: newToDate
      }
    };
    showTransactions(transactions.value, filter)
  }

  const exportTx = () => {
    try {
      let isFileSaverSupported = !!new Blob
      if (document.getElementById('accountName').value === '') {
        alert('Je dient een naam in te vullen voor het maken van een export');
        return
      }
      if (isFileSaverSupported) {
        let creationDate = new Date();
        let accountName = (document.getElementById('accountName').value) ? document.getElementById('accountName').value : '...'
        let MsgId = 'CAMT053';
        let StmtId = MsgId+creationDate.valueOf();
        let xmlContent = '<?xml version="1.0" encoding="UTF-8"?>'
        xmlContent += '<Document xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns="urn:iso:std:iso:20022:tech:xsd:camt.053.001.02">';
        xmlContent += '<BkToCstmrStmt>'
        xmlContent += '<GrpHdr>' // Group header
        xmlContent +=   '<MsgId>'+MsgId+'GRHD0000002024000</MsgId>' // Message Identification ex CAMT053RBB000023623021
        xmlContent +=   '<CreDtTm>'+creationDate.toISOString()+'</CreDtTm>' // Creation Date / Time Stamp ex 2023-10-19T09:29:10.155014+01:00
        xmlContent += '</GrpHdr>'
        // Begin statement
        xmlContent += '<Stmt>' // Statement
        xmlContent +=   '<Id>'+StmtId+'</Id>' // ID ex CAMT053RBB000023623021
        xmlContent +=   '<ElctrncSeqNb>202400000</ElctrncSeqNb>' // Electronic Sequence Number ex 23261
        xmlContent +=   '<CreDtTm>'+creationDate.toISOString()+'</CreDtTm>' // Creation Date / Time Stamp of the Statement record ex 2023-10-19T09:29:10.155014+01:00
        xmlContent +=   '<Acct>' // Account
        xmlContent +=     '<Id>'
        xmlContent +=       '<Othr>'
        xmlContent +=         '<Id>' + statusStore.wallet.accounts[0].address + '</Id>' // Wallet address of client
        xmlContent +=         '<Issr>' + accountName + '</Issr>'
        xmlContent +=       '</Othr>'
        xmlContent +=     '</Id>'
        xmlContent +=     '<Ccy>EUR</Ccy>' // Currency
        xmlContent +=   '</Acct>'
        const txForExport = transactions.value
        let balance = Number(statusStore.getRoundedBalanceActiveAccount)
        if (isNaN(balance) || balance === undefined) {
          let msg = 'Could not get balance of your account, please reload the app.'
          alert(msg)
          throw msg
        }
        for (const tx of txForExport) {
          // Multiply with 1000 to transform the timestamp from second to milliseconds
          const txTimstamp = parseInt(tx.timeStamp) * 1000
          const txDate = new Date(txTimstamp)
          const txDateString = txDate.toISOString().split('T')[0]
          // Create last closing balance
          xmlContent += newBalance('PRCD', balance, 'DBIT', txDateString)
          // Create opening balance
          xmlContent += newBalance('OPBD', balance, 'DBIT', txDateString)
          // Create closing balance
          const DebitOrCredit = tx.to === statusStore.wallet.accounts[statusStore.activeAccountIndex].address.toLowerCase() ? 'DBIT' : 'CRDT'
          let AmntTx = Number((Math.round(formatEther(tx.value) * 1e2) / 1e2).toFixed(2))
          if (DebitOrCredit === 'DBIT') {
            balance = balance + AmntTx
          }
          if (DebitOrCredit === 'CRDT') {
            balance = balance - AmntTx
          }
          balance = Number(balance.toFixed(2))
          xmlContent += newBalance('CLBD', balance, DebitOrCredit, txDateString)
          // Create entry
          const BkTxCd = {
            cd: tx.nonce,
            issr: 'Greenhood'
          }
          xmlContent += newEntry(tx.nonce, AmntTx, DebitOrCredit, 'BOOK', txDateString, txDateString, BkTxCd, tx.hash)
        }
        // -- New balance / transaction here
        // xmlContent +=   '<Bal>' // Balance entry
        // xmlContent +=     '<Tp>' // Type of balance
        // xmlContent +=       '<CdOrPrtry>'
        // xmlContent +=         '<Cd></Cd>' // options: PRCD (last closing balance), OPBD (opening balance), CLBD (closing balance), CLAV (closing available balance), OPAV (opening available balance), ITBD (interim booked), FWAV (ForwardAvailable)
        // xmlContent +=       '</CdOrPrtry>'
        // xmlContent +=     '</Tp>'
        // xmlContent +=     '<Amt Ccy="EUR"></Amt>' // Balance amount with currency ex 1352.65
        // xmlContent +=     '<CdtDbtInd></CdtDbtInd>' // Credit / Debit Indicator options: DBIT (debit), CRDT (credit)
        // xmlContent +=     '<Dt>'
        // xmlContent +=       '<Dt></Dt>' // Date ex 2023-09-17
        // xmlContent +=     '</Dt>'
        // xmlContent +=   '</Bal>'
        // -- End of balance
        // -- New entry here
        // xmlContent +=   '<Ntry>'
        // xmlContent +=     '<NtryRef></NtryRef>'
        // xmlContent +=     '<Amt Ccy="EUR"></Amt>'
        // xmlContent +=     '<CdtDbtInd></CdtDbtInd>'
        // xmlContent +=     '<Sts>BOOK</Sts>'
        // xmlContent +=     '<BookgDt>'
        // xmlContent +=       '<Dt></Dt>'
        // xmlContent +=     '</BookgDt>'
        // xmlContent +=     '<ValDt>'
        // xmlContent +=       '<Dt></Dt>'
        // xmlContent +=     '</ValDt>'
        // xmlContent +=     '<BkTxCd>
        //                      <Prtry>
        //                        <Cd></Cd>
        //                        <Issr></Issr>
        //                      </>Prtry>
        //                    </BkTxCd>'
        // xmlContent +=     '<NtryDtls>'
        // xmlContent +=       '<TxDtls>'
        // xmlContent +=         '<Refs></Refs>'
        // xmlContent +=         '<AmtDtls>'
        // xmlContent +=           '<TxAmt>'
        // xmlContent +=             '<Amt Ccy="EUR"></Amt>'
        // xmlContent +=           '</TxAmt>'
        // xmlContent +=         '</AmtDtls>'
        // xmlContent +=       '</TxDtls>'
        // xmlContent +=     '</NtryDtls>'
        // xmlContent +=   '</Ntry>'
        // End of entry
        xmlContent += '</Stmt>'
        // End of statement
        xmlContent += '</BkToCstmrStmt>'
        xmlContent += '</Document>'
        // Create file
        const xmlFileName = creationDate.valueOf() + '_' + accountName + '_export-tx.xml'
        const xmlFile = new File(
            [xmlContent],
            xmlFileName,
            {type: "application/xml;charset=utf-8"}
        )
        saveAs(xmlFile, xmlFileName, { autoBom: true })
      }
    } catch (e) {
      console.log(e)
    }
  }

  /**
   * @typedef { 'PRCD' | 'OPBD' | 'CLBD' | 'CLAV' | 'OPAV' | 'ITBD' | 'FWAV' } Tp
   * @typedef { 'DBIT' | 'CRDT' } CdtDbtInd
   */

  /**
   * @param {Tp}Tp
   * @param {number}Amt
   * @param {CdtDbtInd}CdtDbtInd
   * @param {string}Dt
   * @returns {string}
   */
  const newBalance = (Tp, Amt, CdtDbtInd, Dt) => {
    let xmlContent = ''
    xmlContent +=   '<Bal>' // Balance entry
    xmlContent +=     '<Tp>' // Type of balance
    xmlContent +=       '<CdOrPrtry>'
    xmlContent +=         '<Cd>'+Tp+'</Cd>' // options: PRCD (last closing balance), OPBD (opening balance), CLBD (closing balance), CLAV (closing available balance), OPAV (opening available balance), ITBD (interim booked), FWAV (ForwardAvailable)
    xmlContent +=       '</CdOrPrtry>'
    xmlContent +=     '</Tp>'
    xmlContent +=     '<Amt Ccy="EUR">'+Amt+'</Amt>' // Balance amount with currency ex 1352.65
    xmlContent +=     '<CdtDbtInd>'+CdtDbtInd+'</CdtDbtInd>' // Credit / Debit Indicator options: DBIT (debit), CRDT (credit)
    xmlContent +=     '<Dt>'
    xmlContent +=       '<Dt>'+Dt+'</Dt>' // Date ex 2023-09-17
    xmlContent +=     '</Dt>'
    xmlContent +=   '</Bal>'
    return xmlContent
  }

  /**
   *
   * @param {string}Ref
   * @param {number}Amt
   * @param {CdtDbtInd}CdtDbtInd
   * @param {string}Sts
   * @param {string}BookgDt
   * @param {string}ValDt
   * @param {Object}BkTxCd
   * @param {string}txHash
   * @returns {string}
   */
  const newEntry = (Ref, Amt, CdtDbtInd, Sts = 'BOOK', BookgDt, ValDt, BkTxCd = {}, txHash) => {
    let xmlContent = ''
    xmlContent +=   '<Ntry>'
    xmlContent +=     '<NtryRef>'+Ref+'</NtryRef>'
    xmlContent +=     '<Amt Ccy="EUR">'+Amt+'</Amt>'
    xmlContent +=     '<CdtDbtInd>'+CdtDbtInd+'</CdtDbtInd>'
    xmlContent +=     '<Sts>'+Sts+'</Sts>'
    xmlContent +=     '<BookgDt>'
    xmlContent +=       '<Dt>'+BookgDt+'</Dt>'
    xmlContent +=     '</BookgDt>'
    xmlContent +=     '<ValDt>'
    xmlContent +=       '<Dt>'+ValDt+'</Dt>'
    xmlContent +=     '</ValDt>'
    xmlContent +=     '<BkTxCd>'
    xmlContent +=       '<Prtry>'
    xmlContent +=         '<Cd>'+BkTxCd.cd+'</Cd>'
    xmlContent +=         '<Issr>'+BkTxCd.issr+'</Issr>'
    xmlContent +=       '</Prtry>'
    xmlContent +=     '</BkTxCd>'
    // new Entry Details
    const Refs = {
      MsgId: txHash
    }
    const AmtDtls = {
      Amt: Amt
    }
    xmlContent +=     '<NtryDtls>'
    xmlContent +=        newTxDetails(Refs, AmtDtls)
    xmlContent +=     '</NtryDtls>'
    xmlContent +=   '</Ntry>'
    return xmlContent
  }

  /**
   *
   * @param {Object}Refs
   * @param {Object}AmtDtls
   * @returns {string}
   */
  const newTxDetails = (Refs = {}, AmtDtls = {}) => {
    let xmlContent = ''
    xmlContent +=       '<TxDtls>'
    xmlContent +=         '<Refs>'
    xmlContent +=           '<MsgId>'+Refs.MsgId+'</MsgId>'
    xmlContent +=         '</Refs>'
    xmlContent +=         '<AmtDtls>'
    xmlContent +=           '<TxAmt>'
    xmlContent +=             '<Amt Ccy="EUR">'+AmtDtls.Amt+'</Amt>'
    xmlContent +=           '</TxAmt>'
    xmlContent +=         '</AmtDtls>'
    xmlContent +=       '</TxDtls>'
    return xmlContent
  }

  onMounted(() => {
    init()
  })

</script>
<template>
  <div class="col-span-full">
    <div class="grid grid-flow-row-dense grid-cols-2">
      <div>
        Datum:
        <Datepicker v-model="pickedDateFrom" :upper-limit="currentDate" typeable :clearable="true" input-format="dd-MM-yyyy" placeholder="van">
          <template v-slot:clear="{ onClear }">
            <svg @click="onClear" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
              <path stroke-linecap="round" stroke-linejoin="round" d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
            </svg>
          </template>
        </Datepicker>
        <Datepicker v-model="pickedDateTo" :lower-limit="pickedDateFrom" typeable :clearable="true" input-format="dd-MM-yyyy" placeholder="t/m">
          <template v-slot:clear="{ onClear }">
            <svg @click="onClear" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
              <path stroke-linecap="round" stroke-linejoin="round" d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
            </svg>
          </template>
        </Datepicker>
        <button class="p-1 w-16" @click="filter">Go</button>
      </div>
      <div>
        Export:
        <input type="text" class="w-80" name="accountName" id="accountName" placeholder="Vul hier jouw naam in">
        <button class="p-1 w-16" @click="exportTx">Go</button>
      </div>
    </div>
    <br />
    <table id="transactionsTable" class="table-auto w-full hidden">
      <thead>
        <th>ID</th>
        <th>Datum</th>
        <th>Type</th>
        <th>Bedrag bij</th>
        <th>Bedrag af</th>
        <th>TX-link</th>
      </thead>
      <tbody>
        <tr v-for="(transaction, key) in displayedTransactions" class="text-center hover:bg-gray-100">
          <td>
            {{ transaction.nonce }}
          </td>
          <td>
            {{ getDate(transaction.timeStamp) }}
          </td>
          <td>
            <span v-if="transaction.to === statusStore.wallet.accounts[0].address.toLowerCase()">
              Bij
            </span>
            <span v-if="transaction.from === statusStore.wallet.accounts[0].address.toLowerCase()">
              Af
            </span>
          </td>
          <td>
            <span v-if="transaction.to === statusStore.wallet.accounts[0].address.toLowerCase()">
              {{ (Math.round(formatEther(transaction.value) * 1e2) / 1e2).toFixed(2) }}
            </span>
          </td>
          <td>
            <span v-if="transaction.from === statusStore.wallet.accounts[0].address.toLowerCase()">
              - {{ (Math.round(formatEther(transaction.value) * 1e2) / 1e2).toFixed(2) }}
            </span>
          </td>
          <td>
            <a :href="[ 'https://gnosisscan.io/tx/' + transaction.hash ]" target="_blank">open</a>
          </td>
        </tr>
      </tbody>
      <tfoot>
        <tr class="text-center bg-gray-50">
          <td colspan="3" class="py-12"></td>
          <td id="totalSum">Totaal bij <span></span></td>
          <td id="totalMin">Totaal af <span></span></td>
          <td id="totalSaldo">Saldo <span></span></td>
        </tr>
      </tfoot>
    </table>
  </div>
</template>

<style scoped>

</style>