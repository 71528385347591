import {defineStore} from 'pinia'
import {useDevice} from "next-vue-device-detector";

/*
 * Generic store management for the app
 */
export const useStatusStore = defineStore('statusstore', {
  state: () => ({
    // type of device, desktop|mobile
    device: useDevice().mobile ? 'mobile' : 'desktop',
    // wallet object with accounts
    wallet: [
      name => '',
      accounts => [{
        address: '',
        balance: {
          'formatted': '',
          'value': 0,
          'symbol': ''
        }
      }],
    ],
    activeAccountIndex: 0,
    // status code
    code: 1,
    // status text
    text: 'Initializing dApp',
    chain: {},
    tokenColor: '#ee0000',
    debug: false
  }),
  getters: {
    /**
     *
     * @param state
     * @returns {number}
     */
    getCode(state) {
      return state.code
    },
    /**
     *
     * @param state
     * @returns {string}
     */
    getText(state) {
      return state.text
    },
    /**
     *
     * @param state
     * @returns {Array}
     */
    getAccounts(state) {
      return state.wallet.accounts
    },
    /**
     *
     * @param state
     * @returns {*}
     */
    getActiveAccount(state) {
      return state.wallet.accounts[this.activeAccountIndex]
    },
    /**
     *
     * @param state
     * @returns {number|*}
     */
    getActiveAccountIndex(state) {
      return state.activeAccountIndex
    },
    /**
     *
     * @param state
     * @returns {string}
     */
    getRoundedBalanceActiveAccount(state) {
      if (state.wallet.accounts[this.activeAccountIndex] !== undefined) {
        return (Math.round(state.wallet.accounts[this.activeAccountIndex].balance.value * 1e2) / 1e2).toFixed(2)
      }
    },
    /**
     *
     * @param state
     * @returns {string}
     */
    getTokenActiveAccount(state) {
      if (state.wallet.accounts[this.activeAccountIndex] !== undefined) {
        return state.wallet.accounts[this.activeAccountIndex].balance.symbol
      }
    }
  },
  actions: {
    /**
     *
     * @param {number}code
     * @param {string}text
     */
    updateStatus: function (code, text) {
      this.code = code
      this.text = text
    },
    /**
     *
     * @param {string}name
     * @param {Array}accounts
     */
    updateWallet: function (name, accounts = []) {
      if (name !== '') {
        this.wallet.name = name
      } else {
        this.wallet.name = ''
      }
      if (accounts.length > 0) {
        this.wallet.accounts = accounts
      }
    },
    /**
     *
     * @param {Object}account
     */
    addAccountToWallet: function (account = {}) {
      if (account !== '' && this.wallet.accounts === undefined) {
        this.wallet.accounts = [account]
      } else {
        this.wallet.accounts.push(account)
      }
    },
    /**
     *
     * @param {string}address
     * @param {Object}balance
     */
    updateBalance: function (address, balance) {
      this.wallet.accounts.forEach((account, index) => {
        if (account.address === address) {
          this.wallet.accounts[index].balance = {
            'formatted': balance.formatted +' '+balance.symbol,
            'value': balance.value,
            'symbol': balance.symbol
          }
        }
      })
    },
    /**
     *
     * @param {string}token
     */
    setAccountIndexByToken: function (token) {
      // These are all branded tokens based on EURe.
      if (token === 'KASH' || token === 'BANT' || token === 'BUP' || token === 'DUKA' || token === 'EURE') {
        // Get account with token EURe
        this.wallet.accounts.forEach((account, index) => {
          if (account.balance.symbol === 'EURe') {
            this.activeAccountIndex = index
          }
        })
      } else {
        let foundToken = false
        this.wallet.accounts.forEach((account, index) => {
          if (account.balance.symbol === token) {
            this.activeAccountIndex = index
            foundToken = true
            return false
          }
        })
        if (foundToken === false) {
          console.log('No '+token+' tokens found in connected wallet')
          alert('It seems you don\'t have '+token+' tokens in your wallet to pay with')
        }
      }
    }
  }
})