import { createApp } from 'vue'
import App from './App.vue'
import { createPinia } from 'pinia'
import { createDeviceDetector } from "next-vue-device-detector"
import * as Sentry from "@sentry/vue";

import './assets/main.css'

const pinia = createPinia()
const app = createApp(App);
export const device = createDeviceDetector()

Sentry.init({
  app,
  dsn: "https://930a15b226664ff7a6fc229b1446bc47@logs.sebastix.dev/17",
  environment: import.meta.env.VITE_ENVIRONMENT,
  trackComponents: true,
  integrations: [
    Sentry.replayIntegration(),
  ],
  tracePropagationTargets: [/^https:\/\/gh.inzicht.sebastix.dev/],
  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  enabled: import.meta.env.VITE_ENVIRONMENT !== 'development',
});

app.use(device)
app.use(pinia)
app.mount('#app')